import Info from '../Components/Pages/Info/Info';
import UsersMainScree from '../Components/views/Users/UsersMainScree';
import AnomaliasView from '../Components/views/Anomalias/index';
import DashboardView from '../Components/views/Dashboard';

export const rutesList = [
  {
    path : '/dashboard',
    cp: DashboardView,
    exact: true,
    roles: ['rol-admin', 'rol-lector']
  },
  {
    path : '/anomalias/estudios',
    cp: AnomaliasView,
    exact: true,
    roles: ['rol-admin', 'rol-lector']
  },
  {
    path : '/anomalias/entrevistador',
    cp: AnomaliasView,
    exact: true,
    roles: ['rol-admin', 'rol-lector']
  },
  {
    path : '/anomalias',
    cp: AnomaliasView,
    exact: true,
    roles: ['rol-admin', 'rol-lector']
  },
  /*{
    path : '/anomalias/:type/:filter',
    cp: AnomaliasView,
    exact: true,
    roles: ['rol-admin', 'rol-lector'] 
  },*/
  {
    path: '/usuarios',
    cp: UsersMainScree,
    exact: true,
    roles: ['rol-admin']
  },
  {
    path: '/info',
    cp: Info,
    exact: true,
    roles: ['rol-admin', 'rol-lector', 'rol-default']
  },
]