import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  card: {
    width: '100%',
  },
  content: {
    minHeight: '80vh'
  }
});

type Props = {
  children: React.ReactNode
}

const MainCardContainer: React.FC<Props> = ({ children }) => {
  const { card, content } = useStyles();

  return (
    <Card className={card}>
      <CardContent className={content}>
        { children }
      </CardContent>
    </Card>
  )
}

export default MainCardContainer;