import { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { checkLocalToken } from '../services/loginService';

type privateRouteProps = {
  isAuth: boolean;
  cp: any;
  path: string;
  exact: boolean;
}

const PrivateRoute: React.FC<privateRouteProps> = ({ isAuth, cp: Component, ...rest }) => {
  const { pathname } = useLocation();

  const executeCheck = async () => {
    await checkLocalToken()
  }

  useEffect(() => {
    executeCheck();
  }, [ pathname ])

  return (
    <Route {...rest}
      component={(props: any) => (
        ( isAuth ) ? <Component { ...props } /> : ( <Redirect to="/info" /> )
      )}
    />
  )
}

export default PrivateRoute;