import axios from "axios";
import { completeSsoRedirect } from "./config/config";
import { deleteAllCookies } from "./helpers/deleteAllCookies";
import { getCookieByName } from "./helpers/getCookieByName";
import { ResponseDataInterceptor } from "./interfaces/interceptorTypes";

axios.interceptors.request.use(
    function (config) {
        const token = getCookieByName('token');
        config.headers = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            Authorization: "Bearer " + token,
        };

        return config;
    },

    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        if(response.status === 202 && response.config.url?.endsWith('check-credentials')) {
            const currentSite = window.location.origin;
            const data: ResponseDataInterceptor = response.data;
            const siteRol = data.user.user.roles.find((item: any) => item.USER_SITE_URL === currentSite);
            localStorage.setItem('rol', siteRol?.USER_SITE_ROL || '');
            if(!siteRol?.USER_SITE_ROL){
                localStorage.setItem('log-ged', 'not-logged');
                window.location.href = "/unauthorized";
            }
        }
        return response;
    },

    function (error) {
        if (error.response.data.statusCode === 401) {
            if (error.response.data.message === "Unauthorized") {
                deleteAllCookies();
                window.location.href = completeSsoRedirect;
            }

        }
        return Promise.reject(error);
    }
);
