import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//components
import {
    TextField,
    Grid,
    LinearProgress,
    Button
  } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PageviewIcon from '@material-ui/icons/Pageview';
import MainCardContainer from '../../../Cards/MainCardContainer';
import MUIDataTable from "mui-datatables";
//services, interfaces
import { 
    getEstudiosCurso,
    ListEstudiosCurso 
} from '../../../../services/dashboardService';
//utils
import moment from 'moment/moment.js';

const EstudiosCursoView = () => {

    const [stateLoading, setStateLoading] = useState<boolean>(false);
    const [stateListEstudiosCurso, setStateListEstudiosCurso] = useState<ListEstudiosCurso[]>([]);

    //fechas
    const now = moment().format('YYYY-MM-DD');
    const oneMonth = moment().subtract(1, 'months').format('YYYY-MM-DD');
    const threeMonth = moment().subtract(3, 'months').format('YYYY-MM-DD');
    const sixMonth = moment().subtract(6, 'months').format('YYYY-MM-DD');

    const listMonth = [
        { title : 'Último mes', value : oneMonth },
        { title : 'Últimos 3 meses', value : threeMonth },
        { title : 'Últimos 6 meses', value : sixMonth },
    ];

    //config grid 
    const columns = [
        {
          name : "ESTUDIO",
          label : "ESTUDIO",
          options: {
            filter: false,
            sort: false
          }
        },
        {
            name : "FECHA",
            label : "FECHA",
            options: {
              filter: false,
              sort: false
            }
        },
        {
            name : "NOMBRE_ESTUDIO",
            label : "NOMBRE_ESTUDIO",
            options: {
              filter: false,
              sort: false
            }
        },
        {
            name : "ANOMALIAS",
            label : "CANTIDAD_ANOMALIAS",
            options: {
              filter: false,
              sort: false
            }
        },
        {
            name : "DETALLE",
            label : " "
        },
    ];

    const options: Object = {
        rowsPerPage : 5,
        textLabels: {
            body: {
                noMatch: "No se encontraron registros",
                toolTip: "Sort",
                columnHeaderTooltip: (column:any) => `Ordenar por ${column.label}`
                },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Registros por pagina:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver en Columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todas",
                title: "Filtros",
                reset: "Limpiar",
            },
            viewColumns: {
                title: "Mostrar",
                titleAria: "Mostrar/Ocultar columnas de tabla",
            },
        },
        searchPlaceholder : "Buscar ...",
        caseSensitive : false,
        selectableRows: false,
        download : true,
        downloadOptions : { filename: 'data.csv', separator: '|' },
        print: false,
        filter: false,
        viewColumns: true,
    };

    let rows:Object[] = [];

    stateListEstudiosCurso.forEach((element:any, index:number) => {
        rows[index] = {
            ANOMALIAS:element.ANOMALIAS,
            ESTUDIO:element.ESTUDIO,
            FECHA:element.FECHA,
            NOMBRE_ESTUDIO: element.NOMBRE_ESTUDIO,
            DETALLE : <Button
                component={Link}
                color="primary"
                variant="contained"
                to={`/anomalias?type=estudios&filter=${element.ESTUDIO}`}
                size="small"
                startIcon = { <PageviewIcon />}
            >
                DETALLE
            </Button>  
        }
    });

    useEffect(() => {
        setStateLoading(true);
        getEstudiosCurso(oneMonth, now).then(resp => {    
            setStateListEstudiosCurso(resp.rows);
            setStateLoading(false);
        });
      }, [now, oneMonth])

    return (
        <MainCardContainer>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                { stateLoading && <LinearProgress />}
                <div>
                    <form>
                        <Autocomplete
                            id="id-filtrar"
                            options={listMonth}
                            getOptionLabel={(option) => option.title}
                            defaultValue={listMonth[0]}
                            onChange={(e, v) => { 
                                if (v!= null) {
                                    const { value : fecha_ini } = v;
                                    setStateLoading(true);
                                    getEstudiosCurso(fecha_ini, now).then(resp => {    
                                        setStateListEstudiosCurso(resp.rows);
                                        setStateLoading(false);
                                    });
                                }
                            }}
                            style={{ width: '20%', paddingTop: '15px' }}
                            renderInput={(params) => 
                            <TextField 
                                {...params} 
                                label="Filtrar"
                                variant="outlined"
                                name="filtrar"
                            />}
                        />
                    </form>
                </div>
                <div style={{ paddingTop : '10px'}}>
                    <MUIDataTable
                        title={""}
                        data={rows}
                        columns={columns}
                        options={options}
                    />
                </div>
                </Grid>
            </Grid>
        </MainCardContainer>
    )
}

export default EstudiosCursoView;
