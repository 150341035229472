import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import DashboardScreen from '../Components/Pages/Dashboard/DashboardScreen';
import PrivateRoute from './PrivateRoute';
// No login components.
import LoginRequireScreen from '../NoLoginComponents/LoginRequireScreen';
import { rutesList } from './RoutesList';
import { getCookieByName } from '../helpers/getCookieByName';
import { UnauthorizedScreen } from '../NoLoginComponents/UnauthorizedScreen';

const AppRouter: React.FC = () => {
  const localToken: string = getCookieByName() || '';
  const logged: string = localStorage.getItem('log-ged') || '';
  const rolUser: string = localStorage.getItem('rol') || '';

  const haveRol = (arrRole: Array<string>, rolUser: string = '') => {
    return arrRole.some(rol => rol === rolUser);
  }

  // si logged tiene algún valor,
  if(logged){
    return (
      <Router>
        <Switch>
          <Route exact path="/unauthorized" component={ UnauthorizedScreen } />
          <Redirect to="/unauthorized" />
        </Switch>
      </Router>
    )
  }

  // rutas publicas.
  if(!localToken || !rolUser){
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={ LoginRequireScreen } />
          <Route exact path="/unauthorized" component={ UnauthorizedScreen } />
          <Redirect to="/" />
        </Switch>
      </Router>
    )
  }

  // rutas privadas.
  return (
    <Router>
      <DashboardScreen>
        <Switch>

          {
            rutesList.map(route => (
              <PrivateRoute
                key={route.path}
                cp={route.cp}
                path={route.path}
                exact={route.exact}
                isAuth={ haveRol(route.roles, rolUser) }
              />
            ))
          }

          <Redirect to="/dashboard" />
        </Switch>
      </DashboardScreen>
    </Router>
  );
};

export default AppRouter;
