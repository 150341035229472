import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
  } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MUIDataTable from "mui-datatables";
//interfaces
import { ListAnomalias } from '../../../../services/anomaliasEstudioService';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  mtop : {
    marginTop : '10px'
  }
}));

type Props = {
    stateOpenModal: boolean;
    setStateOpenModal: (open: boolean) => void;
    stateDataDetails:ListAnomalias[]
};

export default function ModalDetailsView({ 
  stateOpenModal,
  setStateOpenModal,
  stateDataDetails
}:Props) {

    const classes = useStyles();

    const handleClose = () => {
        setStateOpenModal(false)
    };

    interface ListDetails {
        KEY: string,
        ESTUDIO: string,
        ENTREVISTADOR: number,
        REGISTRO: number,
        FECHAINI: string,
        HORAINI: string,
        FECHAFIN: string,
        HORAFIN: string,
        NOMBRE_USUARIO: string | null,
        DIRECCION: string | null,
        REGION: string | null,
        COMUNA: string | null
        EDAD: string | null ,
        SEXO: string | null,
        GSE: string | null,
        TIPO_ESTUDIO: string | null
    };

    const rows : ListDetails[] = [];

    stateDataDetails.forEach((element, index) => {
        rows[index] = {
            KEY: `${element.ESTUDIO}-${element.REGISTRO}-${element.ENTREVISTADOR}`,
            ESTUDIO: element.ESTUDIO,
            REGISTRO: element.REGISTRO,
            ENTREVISTADOR: element.ENTREVISTADOR,
            FECHAINI: element.FECHAINI,
            HORAINI: element.HORAINI,
            FECHAFIN: element.FECHAFIN,
            HORAFIN: element.HORAFIN,
            NOMBRE_USUARIO: element.nombre_usuario,
            DIRECCION: element.DIRECCION,
            REGION: element.REGION,
            COMUNA: element.COMUNA,
            EDAD: element.EDAD,
            SEXO: element.SEXO,
            GSE: element.GSE,
            TIPO_ESTUDIO : element.TIPO_ESTUDIO
        };
    });

    //config grid  
    const columns = [
        {
        name : "ESTUDIO",
        label : "ESTUDIO",
        
        options: {
            filter: false,
            sort: false,
            empty: true,
        }
        },
        {
            name: "ESTADO",
            label: "ESTADO",
            options: {
            filter: true,
            sort: false,
            empty: true
            }
        },
        {
            name: "ENTREVISTADOR",
            label: "ENTREVISTADOR",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "nombre_usuario",
            label: "NOMBRE_ENTREVISTADOR",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "REGISTRO",
            label: "REGISTRO",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "FECHAFIN",
            label: "FECHAFIN",
            options: {
                filter: false,
                sort: true
            }
        },
        {
        name: "TELEFONO",
        label: "TELEFONO",
        options: {
            filter: true,
            sort: true
        }
        },
        {
            name: "COUNTER",
            label: "COUNTER",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "TIPO_ESTUDIO",
            label: "TIPO_ESTUDIO"
        }
    ];

    const options: Object = {
        rowsPerPage : 10,
        expandableRows: true,
        renderExpandableRow: (rowData:any, rowMeta:any) => {

            const codEstudio:string = rowData[0];
            const entrevistador:number = rowData[2];
            const registro:number = rowData[4];
            
            let dataFilter = rows.filter(x => x.ESTUDIO === codEstudio 
                                    && x.ENTREVISTADOR === entrevistador 
                                    && x.REGISTRO === registro);
            return (
                <>
                    <tr>
                        <td colSpan={6} style={{ padding: '10px 10px 10px 10px' }}>
                            <TableContainer component={Paper}>
                                <Table style={{ minWidth: "650" }} aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">FECHA_INI</TableCell>
                                        <TableCell align="right">HORA_INI</TableCell>
                                        <TableCell align="right">FECHA_FIN</TableCell>
                                        <TableCell align="right">HORA_FIN</TableCell>
                                        <TableCell align="right">ENTREVISTADOR</TableCell>
                                        <TableCell align="right">NOMBRE_ENTREVISTADOR</TableCell>
                                        <TableCell align="right">DIRECCIÓN</TableCell>
                                        <TableCell align="right">REGIÓN</TableCell>
                                        <TableCell align="right">COMUNA</TableCell>
                                        <TableCell align="right">EDAD</TableCell>
                                        <TableCell align="right">SEXO</TableCell>
                                        <TableCell align="right">GSE</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataFilter.map(row => (
                                    <TableRow key={row.KEY}>
                                        <TableCell component="th" scope="row">
                                        {row.FECHAINI}
                                        </TableCell>
                                        <TableCell align="right">{row.HORAINI}</TableCell>
                                        <TableCell align="right">{row.FECHAFIN}</TableCell>
                                        <TableCell align="right">{row.HORAFIN}</TableCell>
                                        <TableCell align="right">{row.ENTREVISTADOR}</TableCell>
                                        <TableCell align="right">{row.NOMBRE_USUARIO}</TableCell>
                                        <TableCell align="right">{row.DIRECCION}</TableCell>
                                        <TableCell align="right">{row.REGION}</TableCell>
                                        <TableCell align="right">{row.COMUNA}</TableCell>
                                        <TableCell align="right">{row.EDAD}</TableCell>
                                        <TableCell align="right">{row.SEXO}</TableCell>
                                        <TableCell align="right">{row.GSE}</TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </tr>
                </>
            );
        },
        textLabels: {
            body: {
                noMatch: "No se encontraron registros",
                toolTip: "Sort",
                columnHeaderTooltip: (column:any) => `Ordenar por ${column.label}`
                },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Registros por pagina:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver en Columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todas",
                title: "Filtros",
                reset: "Limpiar",
            },
            viewColumns: {
                title: "Mostrar",
                titleAria: "Mostrar/Ocultar columnas de tabla",
            },
        },
        searchPlaceholder : "Buscar ...",
        caseSensitive : false,
        selectableRows: false,
        download : true,
        downloadOptions : { filename: 'data.csv', separator: '|' },
        print: true,
        filter: true,
        viewColumns: true,
    };

    return (
        <div>
        <Dialog fullScreen open={stateOpenModal} onClose={handleClose}>
            <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{ backgroundColor: '#FD385B' }}>
                <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                CERRAR
                </Typography>
            </Toolbar>
            </AppBar>
            <DialogTitle id="alert-dialog-slide-title">{"Detalles Telefono"}</DialogTitle>
            <DialogContent>
            <Grid item md={12}>
                <MUIDataTable
                    title={""}
                    data={stateDataDetails}
                    columns={columns}
                    options={options}
                />
            </Grid>
            </DialogContent>
        </Dialog>
        </div>
    );
}
