import axios from "axios"
import { API_BASE_URL } from "../config/config";

export interface ListAnomalias {
    ESTUDIO: string,
    ESTADO: number,
    ENTREVISTADOR: number,
    REGISTRO: number,
    FECHAINI: string,
    HORAINI: string,
    FECHAFIN: string,
    HORAFIN: string,
    TELEFONO: string,
    COUNTER: number,
    nombre_usuario : string,
    fecha_alta : Date,
    fecha_cierre: Date,
    id_estudio: number,
    NOMBRE: string,
    DIRECCION : string,
    EDAD: string,
    SEXO: string,
    GSE: string,
    REGION: null,
    COMUNA: null,
    TIPO_ESTUDIO: string
};

export interface ListEstudios {
  title : string,
  value : string
};

export const getAnomaliasforStudy = async (codEstudio : string) => {
    try {
      const response = await axios({
        url: `${API_BASE_URL}/anomalias/filter/${codEstudio}`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return { ok: false, error }
    }
};

export const getDetailsPhone = async (phone : string) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/anomalias/details/${phone}`,
      method: 'GET',
    });
    return response.data;
  } catch (error) {
    return { ok: false, error }
  }

};

export const getAllStudy = async () => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/anomalias/get-all-estudios`,
      method: 'GET',
    });
    return response.data;
  } catch (error) {
    return { ok: false, error }
  };

}