import { useRef, useEffect, useState } from "react";
import { useGlobalContext } from "../../../context/AppContext";
// components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LinearProgress from '@material-ui/core/LinearProgress';
import MainCardContainer from "../../Cards/MainCardContainer";
import AddUserModal from './UserModals/AddUserModal'
import UpdatePasswordModal from "./UserModals/UpdatePasswordModal";
import RoleUser from "./UserModals/RoleUser";
// icons.
import AddIcon from "@material-ui/icons/Add";
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
// services / interfaces
import { getAllUsers, UserFromFsI } from "../../../services/usersService";
import MaterialTableComponent from "../../MaterialTable/MaterialTableComponent";

const currentOrigin = window.location.origin;
const UsersMainScree: React.FC<{}> = () => {
  const { setTitle } = useGlobalContext();
  const isMounted = useRef(false);
  // cp states
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserFromFsI[]>([]);

  // modals state.
  const [openAddUserModal, setOpenAddUserModal] = useState<boolean>(false);
  const [modeModal, setModeModal] = useState<boolean>(true);
  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false);
  const [openModalRole, setOpenModalRole] = useState<boolean>(false);
  const [currentUserId, setCurrentUserId] = useState<string>('');
  const [currentUserEmail, setCurrentUserEmail] = useState<string>('');
  const [currentUser, setCurrentUser] = useState<UserFromFsI>();
  const [roleUser, setRoleUser] = useState<string>('');

  const updateUserList = (user: UserFromFsI) => {
    setUsers([ ...users, user ]);
  }

  const findUserAndUpdate = (user: UserFromFsI) => {
    const listUpdated = users.map(u => u.id === user.id ? user : u);
    setUsers(listUpdated);
  }

  const showModalInCreateMode = () => {
    setModeModal(true);
    setOpenAddUserModal(true);
  }

  const showModalInEditMode = (user: UserFromFsI) => {
    setCurrentUser(user);
    setModeModal(false);
    setOpenAddUserModal(true);
  }

  const showModalChangePassword = (userId: string) => {
    setCurrentUserId(userId);
    setOpenPasswordModal(true);
  }

  const showModalRole = (id: string, userEmail: string, roleUser: string = "") => {
    setRoleUser(roleUser);
    setCurrentUserId(id);
    setCurrentUserEmail(userEmail);
    setOpenModalRole(true);
  }

  const getUserRol = (sites: any[]): string => {
    if(sites){
      const userRol = sites.find(item => item.USER_SITE_URL === currentOrigin);
      if(userRol){
        return userRol.USER_SITE_ROL;
      }
    }
    return '';
  }

  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) setTitle('Usuarios');
    return () => {isMounted.current = false}
  }, [setTitle])

  useEffect(() => {
    setLoading(true);
    getAllUsers()
      .then(resp => setUsers(resp.users))
      .finally(() => setLoading(false));
  }, [])

  return (
    <MainCardContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={() => showModalInCreateMode()}
            startIcon={<AddIcon />}
          >
            Añadir usuario
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          { loading && <LinearProgress />}
          <MaterialTableComponent
            columns={[
              { title: 'Correo electronico', field: 'USER_EMAIL' },
              { title: 'Rol',
                render: (r: any) => {
                  const rol = getUserRol(r.USER_SITES);
                  return rol ? rol : 'Rol sin definir';
                }
              },
            ]}
            data={users}
            detailPanel={(row: any) => (
              <Container maxWidth="lg" style={{ padding: '20px' }}>
                <Grid container spacing={3}>
                  <Grid item sm={3} xs={12}>
                    <Button
                      fullWidth
                      color="primary"
                      startIcon={<EditIcon />}
                      onClick={ () => showModalInEditMode(row) }
                    >
                      Actualizar datos
                    </Button>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <Button
                      fullWidth
                      color="primary"
                      startIcon={<VpnKeyIcon />}
                      onClick={ () => showModalChangePassword(row.id) }
                    >
                      Actualizar contraseña
                    </Button>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <Button
                      fullWidth
                      color="primary"
                      startIcon={<AssignmentIndIcon />}
                      onClick={() => showModalRole(row.id, row.USER_EMAIL, getUserRol(row.USER_SITES))}
                    >
                      Rol
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            )}
          />
        </Grid>
      </Grid>

      {openAddUserModal && (
        <AddUserModal
          open={openAddUserModal}
          setOpen={setOpenAddUserModal}
          addNewProject={updateUserList}
          updateUserList={findUserAndUpdate}
          editUser={currentUser}
          createMode={modeModal}
        />
      )}

      {openPasswordModal && (
        <UpdatePasswordModal
          open={openPasswordModal}
          setOpen={setOpenPasswordModal}
          userId={currentUserId}
          updateUserList={() => {}}
        />
      )}

      {openModalRole && (
        <RoleUser
          open={openModalRole}
          setOpen={setOpenModalRole}
          updateUserList={findUserAndUpdate}
          userEmail={currentUserEmail}
          userId={currentUserId}
          userRole={roleUser}
        />
      )}

    </MainCardContainer>
  )
}

export default UsersMainScree
