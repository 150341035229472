import { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
// modals.
import { DialogTitle, DialogActions, DialogContent } from "../../../Modal/ModalStructure";
// validacion de formulario.
import { Formik } from "formik";
// schemas.
import { validationSchemaEditPassword } from "./validationSchemas";
import { updatePassword } from "../../../../services/usersService";

type Props = {
  open: boolean;
  setOpen: Function;
  updateUserList: Function;
  userId: string;
};

const UpdatePasswordModal: React.FC<Props> = ({ open, setOpen, updateUserList, userId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleClose = () => {
    if (!isLoading) {
      setError('');
      setOpen(false);
    }
  }

  const formValues = {
    userPassword: "",
  };

  return (
    <Dialog
      aria-labelledby="modal-pryecto"
      maxWidth="sm"
      fullWidth={true}
      open={open}
    >
      { isLoading && <LinearProgress />}
      <Formik
        initialValues={formValues}
        validationSchema={validationSchemaEditPassword}
        onSubmit={async (values, { resetForm }) => {
          setIsLoading(true);
          const data = await updatePassword(userId, values.userPassword);
          if(data.ok){
            setIsLoading(false);
            resetForm();
            handleClose();
          }else{
            setIsLoading(false);
            setError(data.error.message);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="modal-pryecto" onClose={handleClose}>
              Actualizar contraseña.
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                { error && (
                  <Grid item xs={12} >
                    <Alert severity="error">{ error }</Alert>
                  </Grid>
                )}
                <Grid item xs={12} >
                  <TextField
                    required
                    fullWidth
                    name="userPassword"
                    variant="outlined"
                    label="Contraseña"
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.userPassword}
                    error={touched.userPassword && Boolean(errors.userPassword)}
                    helperText={touched.userPassword && errors.userPassword}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isLoading}
              >
                Guardar
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdatePasswordModal;