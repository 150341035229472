import { useState } from "react";
import { downloadAllBase, downloadBase } from "../../../../services/dashboardService";
import MainCardContainer from "../../../Cards/MainCardContainer";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { es } from "date-fns/locale";
import Swal from "sweetalert2";

const errorMessage = 'No fue posible descargar el contenido deseado';
const DownloadTabScreen = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dateInit, setDateInit] = useState<Date>(new Date());
  const [dateFin, setDateFin] = useState<Date>(new Date());

  const [dateInitEct, setDateInitEct] = useState<Date>(new Date());
  const [dateFinEct, setDateFinEct] = useState<Date>(new Date());

  const handleChangeDateInit = (date: Date) => setDateInit(date);
  const handleChangeDateFin = (date: Date) => setDateFin(date);

  const handleChangeDateInitEct = (date: Date) => setDateInitEct(date);
  const handleChangeDateFinEct = (date: Date) => setDateFinEct(date);

  const startDownloadFromUrl = (url: string, filename: string): void => {
    const linkDownload = document.createElement('a');
    linkDownload.href = url;
    linkDownload.download = filename;
    linkDownload.target = "_blank";
    linkDownload.click();
  }

  const handleDownloadBase = async () => {
    if(!dateFin || !dateInit){
      return Swal.fire('', 'Debe proporcionar un rango valido', 'warning');
    }

    setLoading(true);

    const data = await downloadBase(
      dateInit.toISOString().split('T')[0],
      dateFin.toISOString().split('T')[0]
    );

    setLoading(false);
    if(data.ok){
      startDownloadFromUrl(data.url, data.fileName);
    }else{
      Swal.fire('', errorMessage, 'error');
    }

  }

  const handleDownloadAllBase = async () => {
    if(!dateFinEct || !dateInitEct){
      return Swal.fire('', 'Debe proporcionar un rango valido', 'warning');
    }

    setLoading(true);
    const data = await downloadAllBase(
      dateInitEct.toISOString().split('T')[0],
      dateFinEct.toISOString().split('T')[0]
    );
    setLoading(false);

    if(data.ok){
      startDownloadFromUrl(data.url, data.fileName);
    }else{
      Swal.fire('', errorMessage, 'error');
    }

  }

  return (
    <MainCardContainer>
      { loading && <LinearProgress /> }
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <MuiPickersUtilsProvider
          locale={es}
          utils={DateFnsUtils}
        >
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Fecha desde"
            value={dateInit}
            onChange={handleChangeDateInit}
            KeyboardButtonProps={{
              'aria-label': 'Actualizar fecha',
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Fecha hasta"
            value={dateFin}
            onChange={handleChangeDateFin}
            KeyboardButtonProps={{
              'aria-label': 'Actualizar fecha',
            }}
          />
        </MuiPickersUtilsProvider>

        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadBase}
        >
          Descargar contenido
        </Button>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <MuiPickersUtilsProvider
          locale={es}
          utils={DateFnsUtils}
        >
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Fecha desde"
            value={dateInitEct}
            onChange={handleChangeDateInitEct}
            KeyboardButtonProps={{
              'aria-label': 'Actualizar fecha',
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Fecha hasta"
            value={dateFinEct}
            onChange={handleChangeDateFinEct}
            KeyboardButtonProps={{
              'aria-label': 'Actualizar fecha',
            }}
          />
        </MuiPickersUtilsProvider>

        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadAllBase}
        >
          Descargar encuestas
        </Button>
      </Grid>
    </MainCardContainer>
  )
}

export default DownloadTabScreen;
