import React, { useEffect } from 'react';
import { useGlobalContext } from '../../../context/AppContext';
import {
    Container,
    Tab,
    makeStyles
  } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import ViewListIcon from '@material-ui/icons/ViewList';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
//subviews
import AnomaliasEstudiosView from './SubviewAnomalias/AnomaliasEstudiosView';
import AnomaliasEntrevistadorView from './SubviewAnomalias/AnomaliasEntrevistador';

  const useStyles = makeStyles((theme) => ({
    root: {
      //backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    paper:{
        width: '100%'
    },
    container: {
      maxHeight: 440,
    },
  }));

const AnomaliasView = () => {

    let params = new URLSearchParams(window.location.search);
    let type = params.get("type");

    const { setTitle } = useGlobalContext();

    const classes = useStyles();
    const [value, setValue] = React.useState('1');
 
    const handleChange = (event:any, newValue:string) => {
        setValue(newValue);
    };

    useEffect(() => {
        setTitle(`Anomalias`);
        if(type==='entrevistador'){
            setValue('2');
        }
    }, [setTitle, type])

    return (
        <Container maxWidth={false}>
            <div className={classes.root}>
                <TabContext value={value}>
                    <AppBar position="static">
                    <TabList onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Estudio"  icon={<ViewListIcon />}  value="1"/>
                        <Tab label="Entrevistador"  icon={<FindReplaceIcon />} value="2" />
                    </TabList>
                    </AppBar>
                    <TabPanel value="1">
                      <AnomaliasEstudiosView />
                    </TabPanel>
                    <TabPanel value="2">
                      <AnomaliasEntrevistadorView />
                    </TabPanel>
                </TabContext>
            </div>
        </Container>
    )
}

export default AnomaliasView;
