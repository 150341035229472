import axios from "axios"
import { API_BASE_URL } from "../config/config";

export interface ListRank {
    ENTREVISTADOR: number,
    ANOMALIAS : number,
    USERNAME : string
};

export interface ListEstudiosCurso {
  ESTUDIO : string,
  ANOMALISAS : number,
  NOMBRE_ESTUDIO : string
};


export const getRankingEntrevistador = async (fecha_ini : string, fecha_fin : string) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/dashboard/ranking-entrevistador/${fecha_ini}/${fecha_fin}`,
      method: 'GET',
    });
    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
};

export const getEstudiosCurso = async (fecha_ini : string, fecha_fin : string) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/dashboard/estudios-curso/${fecha_ini}/${fecha_fin}`,
      method: 'GET',
    });
    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
};

export const downloadBase = async (fecha_ini : string, fecha_fin : string) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/dashboard/download-base/${fecha_ini}/${fecha_fin}`,
      method: 'GET',
    });
    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
};

export const downloadAllBase = async (fecha_ini : string, fecha_fin : string) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/dashboard/download-all-base/${fecha_ini}/${fecha_fin}`,
      method: 'GET',
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}
