import { useState, useEffect } from 'react';
//components
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainCardContainer from "../../../Cards/MainCardContainer";
import {
    Button,
    TextField,
    Grid,
  } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import MUIDataTable from "mui-datatables";
//services , interfaces 
import { 
    ListEntrevistadores,
    ListAnomaliasEntrevistador,
    getAllEntrevistadores,
    getAnomaliasforEntrevistador
} from '../../../../services/anomaliasEntrevistadorService'; 
import { getDetailsPhone, ListAnomalias } from  '../../../../services/anomaliasEstudioService'; 
//modals
import ModalDetailsView from '../Modals/modalDetails'; 

const AnomaliasEntrevistadorView = () => {

    let params = new URLSearchParams(window.location.search);
    let type = params.get("type");
    let filter = params.get("filter") || '';
    
    const [stateListAnomaliasEntrevistador, setStateListAnomaliasEntrevistador] = useState<ListAnomaliasEntrevistador[]>([]);
    const [stateListEntrevistadores, setStateListEntrevistadores] = useState<ListEntrevistadores[]>([]);
    const [stateLoading, setStateLoading] = useState<boolean>(false);
    //modal
    const [stateOpenModal, setStateOpenModal] = useState<boolean>(false);
    const [stateDataDetails, setStateDataDetails] = useState<ListAnomalias[]>([]);

    //arrowFunctions
    const handleLoadModal = (phone:string) => {
        setStateLoading(true);
        getDetailsPhone(phone).then(resp => {
            setStateDataDetails(resp.rows);
            setStateLoading(false);
            setStateOpenModal(true);
        });
    };

    useEffect(() => {
        getAllEntrevistadores().then(resp => {
            if(resp.ok){ 
                setStateListEntrevistadores(resp.rows);
            }
        });

        if(filter !== undefined && type === 'entrevistador'){
            setStateLoading(true);
            getAnomaliasforEntrevistador(filter).then(resp => {
                setStateListAnomaliasEntrevistador(resp.rows);
                setStateLoading(false);
            });
        }

    }, [filter, type]);

    //config grid  
    const columns = [
        {
          name : "ESTUDIO",
          label : "ESTUDIO",
          
          options: {
            filter: false,
            sort: false,
            empty: true,
          }
        },
        {
            name: "ESTADO",
            label: "ESTADO",
            options: {
              filter: true,
              sort: false,
              empty: true
            }
          },
        {
            name: "ENTREVISTADOR",
            label: "ENTREVISTADOR",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "NOMBRE_USUARIO",
            label: "NOMBRE_ENTREVISTADOR",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "FECHAFIN",
            label: "FECHAFIN",
            options: {
                filter: false,
                sort: true
            }
        },
        {
          name: "TELEFONO",
          label: "TELEFONO",
          options: {
              filter: true,
              sort: true
          }
        },
        {
            name: "COUNTER",
            label: "COUNTER",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name : "DETALLE",
            label : " "
        },
    ];


    let rows: Object[] = [];

    stateListAnomaliasEntrevistador.forEach((el:any, index:number) => {
        rows[index] = {
            ESTUDIO: el.ESTUDIO,
            ESTADO: el.ESTADO,
            ENTREVISTADOR: el.ENTREVISTADOR,
            REGISTRO: el.REGISTRO,
            FECHAFIN : el.FECHAFIN,
            HORAFIN: el.HORAFIN,
            TELEFONO: el.TELEFONO,
            COUNTER: el.COUNTER,
            NOMBRE_USUARIO: el.nombre_usuario,
            DETALLE : <Button
                color="primary"
                variant="contained"
                onClick={handleLoadModal.bind(this,el.TELEFONO)}
                size="small"
                startIcon = { <PageviewIcon />}
            >
                DETALLE
            </Button>
        };
    });
    
    

    const options: Object = {
      rowsPerPage : 10,
      textLabels: {
          body: {
              noMatch: "No se encontraron registros",
              toolTip: "Sort",
              columnHeaderTooltip: (column:any) => `Ordenar por ${column.label}`
              },
          pagination: {
              next: "Siguiente",
              previous: "Anterior",
              rowsPerPage: "Registros por pagina:",
              displayRows: "de",
          },
          toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver en Columnas",
              filterTable: "Filtrar tabla",
          },
          filter: {
              all: "Todas",
              title: "Filtros",
              reset: "Limpiar",
          },
          viewColumns: {
              title: "Mostrar",
              titleAria: "Mostrar/Ocultar columnas de tabla",
          },
      },
        searchPlaceholder : "Buscar ...",
        caseSensitive : false,
        selectableRows: false,
        download : false,
        downloadOptions : { filename: 'data.csv', separator: '|' },
        print: false,
        filter: true,
        viewColumns: true,
    };


    return (
        <MainCardContainer>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                { stateLoading && <LinearProgress />}
                <div>
                    <form>
                    <Autocomplete
                        id="id-entrevistadores"
                        options={stateListEntrevistadores}
                        getOptionLabel={(option) => option.title}
                        onChange={(e, v) => { 
                            if (v!= null) {
                                const { value } = v;
                                setStateLoading(true);
                                getAnomaliasforEntrevistador(value.toString()).then(resp => {
                                    setStateListAnomaliasEntrevistador(resp.rows);
                                    setStateLoading(false);
                                });
                            }
                        }}
                        style={{ width: '20%', paddingTop: '15px' }}
                        renderInput={(params) => 
                        <TextField 
                            {...params}
                            label="Entrevistadores"
                            variant="outlined"
                            name="entrevistadores"
                        />}
                    />
                    </form>
                </div>
                <div style={{ paddingTop : '10px'}}>
                    <MUIDataTable
                        title={""}
                        data={rows}
                        columns={columns}
                        options={options}
                    />
                </div>
                <ModalDetailsView
                    stateOpenModal = {stateOpenModal}
                    setStateOpenModal = {setStateOpenModal}
                    stateDataDetails = {stateDataDetails}
                />
                </Grid>
            </Grid>
        </MainCardContainer>
    )
}

export default AnomaliasEntrevistadorView;
