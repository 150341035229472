import axios from "axios"
import { API_BASE_URL, API_SSO_URL } from "../config/config";
const URL = `${API_BASE_URL}/user`;
const URL_SSO = `${API_SSO_URL}/user`;

export interface SiteI {
  SITE_ID: string,
  SITE_ROL: string,
  SITE_URL: string
}

export interface UserFromFsI {
  id: string
  USER_EMAIL: string
  USER_PASSWORD: string
  USER_SITES: Array<SiteI>
  CREATED_AT: Date
}

export interface UserI {
  userEmail: string,
  userPassword: string,
  userRole: string,
}
const origin = window.location.origin;
export const addNewUser = async (user: UserI) => {
  try {
    const response = await axios({
      url: `${URL_SSO}/create-user?origin=${origin}`,
      method: 'POST',
      data: user
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const editCurrentUser = async (user: UserI, userId: string) => {
  try {
    const response = await axios({
      url: `${URL_SSO}/edit-user/${userId}?origin=${origin}`,
      method: 'PUT',
      data: user
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const deleteCurrentUser = async (userId: string) => {
  try {
    const response = await axios({
      url: `${URL_SSO}/delete-user/${userId}?origin=${origin}`,
      method: 'DELETE',
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const updatePassword = async (userId: string, password: string) => {
  try {
    const response = await axios({
      url: `${URL_SSO}/update-password/${userId}?origin=${origin}`,
      method: 'PUT',
      data: { password }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const resetPassword = async (email: string) => {
  try {
    const response = await axios({
      url: `${URL}/reset-password/`,
      method: 'POST',
      data: { email }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const sendPasswordToUpdate = async (password: string) => {
  try {
    const response = await axios({
      url: `${URL}/update-password-user`,
      method: 'PUT',
      data: { password }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const validateToken = async () => {
  try {
    const response = await axios({
      url: `${URL}/check-credentials`,
      method: 'GET',
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const getAllUsers = async () => {
  try {
    const origin = window.location.origin;
    const response = await axios({
      url: `${URL_SSO}/get-users?origin=${origin}`,
      method: 'GET',
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const createRoleUser = async (userEmail: string, userRole: string) => {
  try {
    const origin = window.location.origin;
    const response = await axios({
      url: `${URL_SSO}/create-rol?origin=${origin}`,
      method: 'POST',
      data: { userEmail, userRole }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const updateRoleUser = async (userId: string, newRole: string) => {
  try {
    const origin = window.location.origin;
    const response = await axios({
      url: `${URL_SSO}/update-role/${userId}?origin=${origin}`,
      method: 'PUT',
      data: { newRole }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const removeRolUserById = async (userId: string) => {
  try {
    const origin = window.location.origin;
    const response = await axios({
      url: `${URL_SSO}/remove-role/${userId}?origin=${origin}`,
      method: 'PUT',
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}