import { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@material-ui/lab/Alert';
// modals.
import { DialogTitle, DialogActions, DialogContent } from "../../../Modal/ModalStructure";
// validacion de formulario.
import { Formik } from "formik";
// schemas.
import { validationSchemaRole } from "./validationSchemas";
import { createRoleUser, removeRolUserById, updateRoleUser } from "../../../../services/usersService";

const roleList = [
  { value: 'rol-admin', label: 'rol-admin' },
  { value: 'rol-lector', label: 'rol-lector' },
]

type Props = {
  open: boolean;
  setOpen: Function;
  updateUserList: Function;
  userRole: string;
  userId: string;
  userEmail: string;
};

const RoleUser: React.FC<Props> = ({ open, setOpen, updateUserList, userId, userRole, userEmail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleClose = () => {
    if (!isLoading) {
      setError('');
      setOpen(false);
    }
  }

  const handleRemoveRol = async () => {
    setIsLoading(true);
    const data = await removeRolUserById(userId);

    if(data.ok){
      setIsLoading(false);
      updateUserList(data.user);
      handleClose();
    }else{
      setError(data.error);
      setIsLoading(false);
    }

  }

  const formValues = {
    userRole: userRole,
  };

  return (
    <Dialog
      aria-labelledby="modal-pryecto"
      maxWidth="sm"
      fullWidth={true}
      open={open}
    >
      { isLoading && <LinearProgress />}
      <Formik
        initialValues={formValues}
        validationSchema={validationSchemaRole}
        onSubmit={async (values) => {
          setIsLoading(true);

          if(userRole === ""){
            const data = await createRoleUser(userEmail, values.userRole);
            if(data.ok){
              updateUserList(data.user);
              setIsLoading(false);
              handleClose();
            }
          }else{
            const data = await updateRoleUser(userId, values.userRole);
            if(data.ok){
              updateUserList(data.user);
              setIsLoading(false);
              handleClose();
            }
          }

          setIsLoading(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="modal-pryecto" onClose={handleClose}>
              {userRole === "" ? 'Asignar rol' : 'Actualizar rol'}
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                { error && (
                  <Grid item xs={12} >
                    <Alert severity="error">{ error }</Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={touched.userRole && Boolean(errors.userRole)}
                  >
                    <InputLabel id="rol-user">Rol</InputLabel>
                      <Select
                        required
                        fullWidth
                        labelId="rol-user"
                        id="rol-user"
                        name="userRole"
                        variant="outlined"
                        label="Rol"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.userRole}
                      >
                        {roleList.map((area: {value: string, label: string}) => (
                          <MenuItem
                            key={area.value}
                            value={area.value}
                          >
                            {area.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.userRole && Boolean(errors.userRole) && (
                        <FormHelperText>{errors.userRole}</FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleRemoveRol}
              >
                Eliminar rol
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isLoading}
              >
                {userRole === "" ? 'Asignar rol' : 'Actualizar rol'}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default RoleUser;