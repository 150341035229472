import * as yup from "yup";

export const validationSchemaRole = yup.object({
  userRole: yup
    .string()
    .required("Rol requerido."),
});

export const validationSchemaEditPassword = yup.object({
  userPassword: yup
    .string()
    .min(4, "Se requiren 4 caracteres minimo")
    .max(20, "Se requiren menos de 12 caracteres")
    .required("Contraseña requerida."),
});

export const validationSchemaCreate = yup.object({
  userEmail: yup
    .string()
    .min(2, "Se requiren dos caracteres minimo")
    .max(255, "Se requiren menos de 255 caracteres")
    .email('Formato de correo electronico no valido')
    .required("Correo electronico requerido."),
  userPassword: yup
    .string()
    .min(4, "Se requiren 4 caracteres minimo")
    .max(20, "Se requiren menos de 12 caracteres")
    .required("Contraseña requerida."),
});

export const validationSchemaEdit =  yup.object({
  userEmail: yup
    .string()
    .min(2, "Se requiren dos caracteres minimo")
    .max(255, "Se requiren menos de 255 caracteres")
    .email('Formato de correo electronico no valido')
    .required("Correo electronico requerido."),
});