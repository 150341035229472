import axios from "axios";
import { API_BASE_URL } from "../config/config";

export interface ListEntrevistadores {
  title : string,
  value : number
};

export interface ListAnomaliasEntrevistador {
  ESTUDIO: string,
  ESTADO: number,
  ENTREVISTADOR: number,
  REGISTRO: number,
  FECHAINI: string,
  HORAINI: string,
  FECHAFIN: string,
  HORAFIN: string,
  TELEFONO: string,
  COUNTER: number,
  nombre_usuario : string,
  fecha_alta : Date,
  fecha_cierre: Date,
  id_estudio: number,
  NOMBRE: string,
  DIRECCION : string,
  EDAD: string,
  SEXO: string,
  GSE: string,
  REGION: null,
  COMUNA: null,
  TIPO_ESTUDIO: string
};


export const getAnomaliasforEntrevistador = async (codEntrevistador : string) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/anomalias/filter-entrevistador/${codEntrevistador}`,
      method: 'GET',
    });
    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
};

export const getAllEntrevistadores = async () => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/anomalias/get-all-entrevistadores`,
      method: 'GET',
    });
    return response.data;
  } catch (error) {
    return { ok: false, error }
  };
}