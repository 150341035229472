import React, { useEffect} from 'react'
import { useGlobalContext } from '../../../context/AppContext';

import {
    Container,
    Tab,
    makeStyles
  } from '@material-ui/core';
  import AppBar from '@material-ui/core/AppBar';
  import TabContext from '@material-ui/lab/TabContext';
  import TabList from '@material-ui/lab/TabList';
  import TabPanel from '@material-ui/lab/TabPanel';
  import ViewListIcon from '@material-ui/icons/ViewList';
  import FindReplaceIcon from '@material-ui/icons/FindReplace';
  import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
  //subviews
  import RankingEntrevistadorView from './SubviewsDashboard/RankingEntrevistadorView';
import EstudiosCursoView from './SubviewsDashboard/EstudiosCursoView';
import DownloadTabScreen from './SubviewsDashboard/DownloadTabScreen';

  const useStyles = makeStyles((theme) => ({
    root: {
      //backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    paper:{
        width: '100%'
    },
    container: {
      maxHeight: 440,
    },
  }));

const DashboardView = () => {

    const { setTitle } = useGlobalContext();

    const classes = useStyles();
    const [value, setValue] = React.useState('1');
 
    const handleChange = (event:any, newValue:string) => {
        setValue(newValue);
    };

    useEffect(() => {
        setTitle(`Dashboard`);
      }, [setTitle])

    return (
        <Container maxWidth={false}>
            <div className={classes.root}>
                <TabContext value={value}>
                    <AppBar position="static">
                    <TabList onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Ranking Entrevistador" value="1" icon={<ViewListIcon />} />
                        <Tab label="Estudios en Curso" value="2" icon={<FindReplaceIcon />} />
                        <Tab label="Descarga por fecha" value="3" icon={<CloudDownloadIcon />} />
                    </TabList>
                    </AppBar>
                    <TabPanel value="1">
                      <RankingEntrevistadorView />
                    </TabPanel>
                    <TabPanel value="2">
                      <EstudiosCursoView />
                    </TabPanel>
                    <TabPanel value="3">
                      <DownloadTabScreen />
                    </TabPanel>
                </TabContext>
            </div>
        </Container>
    )
}

export default DashboardView;
